/** @jsx jsx */
import { jsx } from "theme-ui"

import Layout from "../components/layout"

const PaymentSuccess = () => {

  return (
    <Layout >
      <div >
        <h1>Payment Successful!</h1>
        <p>Thank you for your payment and for support a local small business</p>
      </div>
    </Layout>
  )
}

export default PaymentSuccess